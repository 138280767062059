import React from 'react'
import { Routes, Route } from "react-router-dom";
import Account from '../pages/settings/Account';
import AccessList from '../pages/settings/AccessList';
import Alerts from '../pages/settings/Alerts';
import Widgets from '../pages/settings/Widgets';
import AccessGroup from '../pages/settings/AccessGroup';
import CkRoutes from "ck_commun/src/app/modUtils/routes/CkRoutes";

export default function SettingsRoutes() {
    return (
        <CkRoutes>
            <Route path="account" element={<Account/>}/>
            <Route path="access-list" element={<AccessList/>}/>
            <Route path="access-group" element={<AccessGroup/>}/>
            <Route path="alerts" element={<Alerts/>}/>
            <Route path="widgets" element={<Widgets/>}/>
        </CkRoutes>
    );
};