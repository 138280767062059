import React from 'react';
import { Route } from "react-router-dom";
import Logs from '../pages/history/logs/Logs';
import Telecollects from '../pages/history/telecollects/Telecollects';
import Pickups from '../pages/history/pickups/Pickups';
import Sales from '../pages/history/sales/Sales';
import CkRoutes from "ck_commun/src/app/modUtils/routes/CkRoutes";

export default function HistoryRoutes() {
    return (
        <CkRoutes>
            <Route path="logs" element={<Logs/>}/>
            <Route path="telecollects" element={<Telecollects/>}/>
            <Route path="pickups" element={<Pickups/>}/>
            <Route path="sales" element={<Sales/>}/>
        </CkRoutes>
    );
};