import React from "react";
import AccessListPage from "ck_commun/src/app/modManager/pages/AccessListPage";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";
 
export default function AccessList() {

  return (
    <div className="p-4">
      <BuildingPage/>
      {/* <AccessListPage /> */}
    </div>
  );
}
