import React from 'react'
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage'

export default function Alerts() {
  return (
    <div>
      <BuildingPage />
    </div>
  )
}
