import React from 'react'
import { Card } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary'

function StoreCard({icon, label, color}) {
    var storeCard_style = {
        card: {
            boxShadow: "0px 3px 6px #272D3B33",
            borderRadius: "20px",  
            height: "223px",
            minHeight: "223px",
            width :"178px",
            padding: "12px",
            color: "#ffff",
            backgroundColor: color,
            border:"none"
          },
        
       cardBody: {
            padding: "6px 10px 6px 10px",
            display: "flex",
            flexDirection: "column",
            boxSizing: "content-box",

            alignItems:"flex-start"
          },
    }
  return (
    <Card style={storeCard_style}>
        <div><img src={icon}></img></div>
        <div style={{ align:"left", fontWeight:"500", fontSize:"17px" , textTransform:"capitalize"}} >{label}</div>
   
    </Card>
  )
}

export default StoreCard