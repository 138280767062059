import React, { useState, useEffect } from "react";
import { Route ,Navigate} from "react-router-dom";
import Home from "pages/home/Home.jsx";
import Login from "../pages/login/Login";
import Store from "../pages/store/Store";
import ActivitiesRoutes from "./ActivitiesRoutes";
import BadgesAndKeysRoutes from "./BadgesAndKeysRoutes";
import EquipmentRoutes from "./EquipmentRoutes";
import HistoryRoutes from "./HistoryRoutes";
import PromoRoutes from "./PromoRoutes";
import SettingsRoutes from "./SettingsRoutes";
import CrmRoutes from "./CrmRoutes";
import PrivateRoutes from "./PrivateRoutes";
import MainLayout from "shared/components/mainLayout/MainLayout";
import MobilePage from "ck_commun/src/app/modUtils/pages/MobilePage";
import CkRoutes from "ck_commun/src/app/modUtils/routes/CkRoutes";
import Plans from "ck_commun/src/app/modManager/pages/Plans";
import { getAccessToken, isAccessTokenExpired } from "ck_commun/src/app/modUtils/functions/handleTokens";

export default function MainRoutes() {
    const isProd = process.env.NODE_ENV === 'production' ;
    const googlePlayLink = process.env.REACT_APP_GOOGLEPLAY_LINK;
    const appleStoreLink = process.env.REACT_APP_APPLESTORE_LINK;
    const [isMobile, setIsMobile] = useState(false);
    const token = getAccessToken();

    const handleMobileScreen = () => {
        const mobileBreakpoint = 640;
        setIsMobile(window.innerWidth < mobileBreakpoint);
    };
  
    useEffect(() => {
        handleMobileScreen();
    }, []); 

    return (
        <>
        {isMobile && appleStoreLink && googlePlayLink ? (
            <MobilePage appName='Ckwash Manager' googleStoreLink={googlePlayLink} appleStoreLink={appleStoreLink} logo={process.env.PUBLIC_URL + "/img/ckwash-mobile-logo.svg"}/>
        ) : (
            <CkRoutes>
                <Route exact path="/" element={<Login/>}/>
                <Route element={<PrivateRoutes/>}>
                    <Route element={<MainLayout/>}>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="plans" element={<Plans/>}/>
                        <Route path="equipment/*" element={<EquipmentRoutes/>}/>
                        <Route path="activity/*" element={<ActivitiesRoutes/>}/>
                        <Route path="history/*" element={<HistoryRoutes/>}/>
                        <Route path="badges/*" element={<BadgesAndKeysRoutes/>}/>
                        <Route path="settings/*" element={<SettingsRoutes/>}/>

                        {isProd === false && (
                            <>
                            <Route path="promo/*" element={<PromoRoutes/>}/>
                            <Route path="crm/*" element={<CrmRoutes/>}/>
                            <Route path="store" element={<Store/>}/>
                            </>
                        )}
                    </Route>
                </Route>
            </CkRoutes>
        )}
        </>
    );
};