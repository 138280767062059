import React, { useState, useEffect } from "react";
import Layout from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Layout";
import Sider from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Sider";
import Content from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Content";
import LeftSideBar from "../leftSideBar/LeftSideBar";
import RightSideBar from "ck_commun/src/app/modUtils/components/rightSideBar/RightSideBar";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  const [collapsedLeft, setCollapsedLeft] = useState(false);
  const [collapsedRight, setCollapsedRight] = useState(false);

  const handleResize = () => {
    // Set collapsed state based on the window width and breakpoint
    const breakpoint = 1024; // Set breakpoint to half screen
    const isBelowBreakpoint = window.innerWidth <= breakpoint;
    setCollapsedLeft(isBelowBreakpoint);
    setCollapsedRight(isBelowBreakpoint);
  };

  useEffect(() => {
    // Set initial collapsed state on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <Layout>
      <Sider position="left" collapsed={collapsedLeft} setCollapsed={setCollapsedLeft}>
        <LeftSideBar collapsed={collapsedLeft}/>
      </Sider> 
      <Content>
        <Outlet />
      </Content>
      <Sider position="right" collapsed={collapsedRight} setCollapsed={setCollapsedRight} collapseWidth="50px">
        <RightSideBar collapsed={collapsedRight} />
      </Sider>
    </Layout>
  );
}